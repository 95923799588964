export default {
  id: 'accountManagement',
  name: 'FC App Account Management',
  description: 'Manage users and accounts of the FC app.',
  permission: 'fcAppAccountManagement:app',
  icon: 'fa-users',
  version: '0.1.0',
  routes: [{
    path: '/accountManagement',
    name: 'AccountManagement',
    component: () => import('./AccountManagement.vue'),
  }],
};
