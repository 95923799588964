import { useAnalytics } from '@fc/app-common';
import sessionData from '@/shared/utils/sessionData';
import ordersApi from './ordersService';

function getMembership({ state, getters }) {
  if (getters?.membershipInCart) {
    return {
      id: getters?.membershipInCart?.metadata?.stripeSubId,
      trialDays: parseInt(getters.membershipInCart.metadata.stripeTrialDays, 10),
      stripeCoupon: getters.membershipInCart.metadata.stripeCoupon,
    };
  } if (getters?.isHolidayPackageInCart) {
    const getAnnualSub = state?.checkout?.shopifyCollection.find(product => product?.variants?.[0]?.uid === 'fightcamp_subscription_yearly_included');

    // TODO throw bugsnag error
    if (!getAnnualSub) return null;

    return {
      id: getAnnualSub?.metadata?.stripeSubId,
      trialDays: parseInt(getAnnualSub.metadata.stripeTrialDays, 10),
      stripeCoupon: getAnnualSub.metadata.stripeCoupon,
    };
  }

  return null;
}

function mapPaymentIntentRequest({
  shippingDetails, cart, costBreakdown, shippingRateDetails, customerId = null, isPosOn, draftOrderId = null, includesConsole = null,
}) {
  const {
    email, address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
  } = shippingDetails;

  const retailTag = isPosOn ? 'InsideSales' : 'BizDev';

  return {
    order: {
      currency: 'usd',
      email,
      itemList: cart,
      shippingDetails: {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        phone: phoneNumber,
        address,
        address2,
        city,
        state,
        country,
        postalCode,
      },
      tags: [
        retailTag,
        'Shopify API',
        ...(includesConsole ? ['includesConsole'] : []),
      ],
    },
    shippingRateDetails,
    costBreakdown,
    customerId,
    ...(draftOrderId && { draftOrderId }),
  };
}

/*
  Recursive function that calls Shopify API query getCheckout until the checkout is ready
  if for some reason the checkout is not ready after 20 seconds we stop making calls to Shopify
  and throw an error
*/
async function getCheckoutUntilReady({ checkoutId, eCommService, startTime = Date.now() }) {
  // If more than 20 seconds have passed since the first call, throw an error
  if (Date.now() - startTime > 20000) {
    throw new Error('Sorry, there was an error signing you up. Please try again.');
  }

  const checkoutData = await eCommService.getCheckout(checkoutId);
  if (checkoutData.isReady) {
    return checkoutData;
  }
  // Wait for 2 seconds before the next API call
  await new Promise(resolve => setTimeout(resolve, 2000));
  return getCheckoutUntilReady({ checkoutId, eCommService, startTime });
}

async function createOrUpdateShopifyCheckout({
  shippingDetails = null, eCommService, commit, cart = [], couponName, removeCoupon = false,
}, checkoutId = null) {
  let shippingAddress = null;

  if (shippingDetails) {
    const {
      address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
    } = shippingDetails;

    shippingAddress = {
      address1: address,
      address2,
      city,
      province: state,
      country,
      firstName,
      lastName,
      phone: phoneNumber,
      zip: postalCode,
    };
  }

  const request = {
    ...(shippingDetails?.email && { email: shippingDetails.email }),
    ...(shippingAddress && { shippingAddress }),
    lineItems: cart.map((item) => ({
      variantId: item.id,
      quantity: item.qty,
    })),
  };

  let id = checkoutId;

  try {
    if (id) {
      const updatedCheckout = await eCommService.updateCheckout({ ...request, checkoutId: id });
      id = updatedCheckout.checkoutId;

      if (updatedCheckout.error) {
        throw new Error(updatedCheckout.error);
      }

            // Apply Coupon
            if (couponName) {
              const updatedCouponCheckout = await eCommService.applyCoupon({ checkoutId: id, couponCode: couponName });

              if (updatedCouponCheckout.checkoutId) {
                id = updatedCouponCheckout.checkoutId;
              }

              /*
              Shopify will not give an error since the coupon is a valid one.
              If items are not part of the coupon it will just return the order with no discount applied
              TODO figure out why it does not return as https://community.shopify.com/c/hydrogen-headless-and-storefront/handle-discounts-via-the-storefront-api/m-p/1308445
              */
              if (updatedCouponCheckout.errors.length > 0) {
                // only handling showing the first error for now
                throw new Error(updatedCouponCheckout.errors[0]);
              }
            }

            // remove coupon
            if (removeCoupon) {
              const updatedRemoveCoupCheckout = await eCommService.removeCoupon({ checkoutId: id });

              if (updatedRemoveCoupCheckout.checkoutId) {
                id = updatedRemoveCoupCheckout.checkoutId;
              }

              if (updatedRemoveCoupCheckout.errors.length > 0) {
                throw new Error('We are sorry. There was an error removing your promo code', { root: true });
              }
            }

      commit('setCheckoutId', id, { root: true });
    } else {
      const createdCheckout = await eCommService.createCheckout(request);
      commit('setCheckoutId', createdCheckout.checkoutId, { root: true });
      id = createdCheckout.checkoutId;
    }

    const {
      shippingRates,
    } = await getCheckoutUntilReady({ checkoutId: id, eCommService });

    // shippingRates may be null if the product does not require shipping or empty array
    if (shippingRates && shippingRates?.length > 0) {
      console.log('shippingRates:', shippingRates, 'shippingRates[0]:', shippingRates[0], 'id:', id);
      await eCommService.updateCheckoutShippingLine({ checkoutId: id, shippingRateHandle: shippingRates[0].id });
    }

    // get these values at the end until all the mutations are done and data is ready
    const {
      subTotal, totalPrice, totalTax, shippingAmount, totalDuties, discountAmount, couponCode, couponData,
    } = await getCheckoutUntilReady({ checkoutId: id, eCommService });

    const orderObj = {
      costBreakdown: {
        totalCost: totalPrice,
        productTax: totalTax,
        productCost: subTotal,
        productShipping: shippingAmount,
        ...(discountAmount ? { discountAmount } : {}),
        ...(couponCode ? { couponName: couponCode } : {}), // follow existing pattern
        ...(couponData ? { couponData } : {}),
      },
      shippingRateDetails: shippingRates[0],
      totalDuties,
    };
    commit('updateOrder', orderObj, { root: true });

    return orderObj;
  } catch (err) {
    if (err.name === 'ShippingError' && err.message === 'MISSING_SHIPPING_RATE') {
      throw new Error('Product currently not available.');
    }

    throw new Error(err.message);
  }
}

export default {
  actions: {
    async createCheckout({ rootGetters, rootState, commit }, { shippingDetails, eCommService }) {
      commit('setProcessingTotalCost', true, { root: true });

      console.log('store.js - action - createCheckout');
      const { checkoutId } = rootState.checkout; // need to add this to store?

      try {
        await createOrUpdateShopifyCheckout({
          shippingDetails, eCommService, commit, cart: rootGetters.cart, checkoutId,
        });
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        console.error(err);
        if (err.name === 'ProductAvailabilityError') {
          commit('SET_SHIPPING_ERROR', err.message, { root: true });
          commit('SET_SHIPPING_INVALID', ['country'], { root: true });
        } else {
          commit('SET_SHIPPING_ERROR', err, { root: true });
        }
        commit('setProcessingTotalCost', false, { root: true });
      }
    },
    async updateCheckout({ rootGetters, rootState, commit }, { shippingDetails, eCommService }) {
      commit('setProcessingTotalCost', true, { root: true });
      const { checkoutId } = rootState.checkout;

      try {
        await createOrUpdateShopifyCheckout({
          shippingDetails, eCommService, commit, cart: rootGetters.cart,
        }, checkoutId);
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        if (err.name === 'ProductAvailabilityError') {
          commit('SET_SHIPPING_ERROR', err.message, { root: true });
          commit('SET_SHIPPING_INVALID', ['country'], { root: true });
        } else {
          commit('SET_SHIPPING_ERROR', err, { root: true });
        }
        commit('setProcessingTotalCost', false, { root: true });
      }
    },
    // TODO need to refactor confirmOrder to make more performant calls to pg endpoints, since this is a single page checkout the calls are different comapared to Shopify
    async confirmOrder({
      commit, rootState, dispatch, rootGetters,
    }) {
      commit('SET_PROCESSING_PAYMENT_METHOD', true, { root: true });
      commit('SET_PAYMENT_ATTEMPT_ERROR', '', { root: true });

      console.log('store.js - action - confirmOrder');

      const { shipping, order } = rootGetters;
      const { cart } = rootState.checkout;

      try {
        const isExistingCustomer = await dispatch('getCustomer', shipping.email);

        if (isExistingCustomer) {
          await dispatch('updateCustomer', { shippingDetails: shipping, id: rootState.checkout.customerId });
        } else {
          // TODO problem is createCustomer does not attach payment method to them/default need to rework this eventually
          await dispatch('createCustomer', { shippingDetails: shipping });
        }

        const { customerId: existingCustomerId } = rootState.checkout;
        const cartItemsTransformed = cart.map((item) => ({
          id: item.id,
          qty: item.qty,
          type: item.type,
          uid: rootGetters?.cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid ?? null,
        }));

        const orderRequestBody = mapPaymentIntentRequest({
          shippingDetails: shipping, cart: cartItemsTransformed, costBreakdown: order.costBreakdown, shippingRateDetails: order.shippingRateDetails, customerId: existingCustomerId, isPosOn: rootGetters.isPosOn,
        });

        const { data: { paymentIntentId, draftOrderId } } = await ordersApi.createOrder({ requestBody: orderRequestBody });
        commit('setOrderIds', { paymentIntentId, draftOrderId }, { root: true });

        // need to update the Payment Intent with the customerId so that it attaches the customer to the card for subscriptions
        // lets also update shipping details, cart, costBreakdown in case they have changed
        // add this to create as an optoion to so dont have to do so many calls?
        const updateRequestBody = mapPaymentIntentRequest({
          shippingDetails: shipping, cart: cartItemsTransformed, costBreakdown: order.costBreakdown, customerId: existingCustomerId, draftOrderId, includesConsole: rootGetters.includesConsole,
        });
        await ordersApi.updateOrder({ paymentIntentId, requestBody: updateRequestBody });

        const membership = getMembership({ state: rootState, getters: rootGetters });

        const { customerId, new_customer: newCustomer, affirmToken } = rootState.checkout;

        const requestBody = {
          email: shipping.email,
          shippingInfo: {
            address: shipping.address,
            address2: shipping.address2,
            city: shipping.city,
            state: shipping.state,
            country: shipping.country,
            postalCode: shipping.postalCode,
            firstName: shipping.firstName,
            lastName: shipping.lastName,
            phoneNumber: shipping.phoneNumber,
            email: shipping.email,
          },
          costBreakdown: order.costBreakdown,
          shippingRateDetails: order.shippingRateDetails,
          productList: cartItemsTransformed,
          membership,
          affirmLoanId: affirmToken,
          customerId,
          paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id,
          options: {
            paymentMethod: rootState.checkout.paymentInformation?.paymentMethod,
            retail: rootState?.app?.location || null,
            insideSale: rootState?.app?.representative || null,
          },
          sessionData: sessionData.get(),
          newCustomer,
          paymentIntentId,
          draftOrderId,
          anonymousId: typeof window?.analytics?.user === 'function' ? window.analytics.user()?.anonymousId() : null,
        };

        // only set default payment method if sub is in cart
        if (membership) {
          // not ideal but need to update customer here to set default payment method
          await dispatch('updateCustomer', { shippingDetails: shipping, id: rootState.checkout.customerId });
        }

        const {
          data: {
            orderName, orderID, method, experiments,
          },
        } = await ordersApi.confirmOrder({ requestBody });

        commit('setConfirmation', {
          email: shipping.email,
          textPhoneNumber: shipping.phoneNumber,
          orderNumber: parseInt(orderName.split('#')[1], 10), // orderNumber needs to be an integer and cannot have # in it
          orderID,
          order: rootState.checkout.order,
          method,
          cart,
          isNewCustomer: newCustomer,
          serverExperiments: experiments, // TODO
          name: `${shipping.firstName} ${shipping.lastName}`,
          subscription: rootGetters.membershipInCart,
        }, { root: true });

        const products = rootGetters.cartProducts.shopifyItems.map(item => ({
          id: item.id,
          category: item.productType,
          title: `${item.title}, ${item.variant.productName}`,
          price: item.variant.price,
          img_url: item.image,
          variantId: item.variant.id,
        }));

        const analyticsObj = useAnalytics();
        analyticsObj.orderCompleted({ ...rootGetters.confirmation, products, packageInCart: !!rootGetters.packageInCart });

        commit('reset', null, { root: true });
      } catch (err) {
        throw new Error(err);
      }
    },
    async applyCoupon({ rootState, rootGetters, commit }, { coupon, eCommService }) {
      console.log('store.js - action - applyCoupon');
      const { checkoutId } = rootState.checkout;
      const shippingDetails = rootGetters.shipping;

      try {
        commit('setProcessingTotalCost', true, { root: true });

        await createOrUpdateShopifyCheckout({
          eCommService, commit, couponName: coupon, shippingDetails, cart: rootGetters.cart,
        }, checkoutId);

        commit('updateCouponError', '', { root: true });
        commit('updateCoupon', coupon, { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        commit('setProcessingTotalCost', false, { root: true });
        console.error(err);
        // TODO handle displaying correct error message
        commit('updateCouponError', err.message, { root: true });
      }
    },
    async removeCoupon({ rootState, rootGetters, commit }, { eCommService }) {
      console.log('store.js - action - removeCoupon');
      const { checkoutId } = rootState.checkout;
      try {
        commit('setProcessingTotalCost', true, { root: true });

        await createOrUpdateShopifyCheckout({
          eCommService, commit, cart: rootGetters.cart, removeCoupon: true,
        }, checkoutId);
        commit('updateCoupon', '', { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      } catch (err) {
        commit('setProcessingTotalCost', false, { root: true });
        console.error(err);
        // TODO handle displaying correct error message
        commit('updateCouponError', 'We are sorry. There was an error removing your promo code', { root: true });
      }
    },
    // handles sending a request to retrieve an existing customer from backend
    async getCustomer({ commit }, email) {
      console.log('store.js - action - getCustomer');
      let customer = {};

      try {
        const response = await ordersApi.getCustomer({ email });
        [customer] = response.data;

        if (customer) {
          commit('updateCustomerId', customer.id, { root: true });
          commit('setNewCustomer', customer.newSubCustomer, { root: true });
        }

        return customer;
      } catch (err) {
        // TODO handle errors
        console.error(err);

        throw new Error(err);
      }
    },
    // handles sending a request to create a new customer from backend
    async createCustomer({ commit }, payload) {
      console.log('store.js - action - createCustomer');
      let createdCustomer = {};

      try {
        const { shippingDetails } = payload;

        const requestBody = {
          email: shippingDetails.email,
          shippingDetails: {
            name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
            phone: shippingDetails.phoneNumber,
            address: {
              line1: shippingDetails.address,
              line2: shippingDetails.address2,
              city: shippingDetails.city,
              state: shippingDetails.state,
              country: shippingDetails.country,
              postal_code: shippingDetails.postalCode,
            },
          },
        };

        createdCustomer = await ordersApi.createCustomer(requestBody);

        commit('updateCustomerId', createdCustomer.data.id, { root: true });
        commit('setNewCustomer', createdCustomer.data.newSubCustomer, { root: true });

        return createdCustomer.data;
      } catch (err) {
        // TODO handle errors
        console.error(err);

        throw new Error(err);
      }
    },

    async updateCustomer({ rootState }, payload) {
      console.log('store.js - action - updateCustomer');
      let updatedCustomer = null;
      const { shippingDetails = {} } = payload;

      try {
        const requestBody = {
          paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id ?? null,
          ...(shippingDetails && {
            shippingDetails: {
              name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
              phone: shippingDetails.phoneNumber,
              address: {
                line1: shippingDetails.address,
                line2: shippingDetails.address2,
                city: shippingDetails.city,
                state: shippingDetails.state,
                country: shippingDetails.country,
                postal_code: shippingDetails.postalCode,
              },
            },
          }),
        };

        updatedCustomer = await ordersApi.updateCustomer({ id: rootState.checkout.customerId, requestBody });

        return updatedCustomer.data;
      } catch (err) {
        // TODO handle errors
        console.error(err);

        throw new Error(err);
      }
    },
  },
};
