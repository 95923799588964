export default {
  state: {
    isPosOn: false,
    shopifyCollection: [],
    transformedCollection: [],
  },
  actions: {
    async insertItemToCartV2({
      commit, dispatch, getters,
    }, item) {
      try {
        const shouldSwapPackages = getters?.cartProducts?.packageInCart && getters.isShopifyPackage(item.id);
        if (shouldSwapPackages) {
          await dispatch('removeItemFromCartV2', { id: getters.cartProducts?.packageInCart?.variantId }); // this will call the track remove product, was this messing with analytics? should we fix moving forward?
        }

        const productVariant = getters.getProductVariantById(item.id);
        // // verify item ID exists (this handles the use case of removing the Shopify flag and customers having old cart items in their session)
        if (!productVariant) {
          console.error('There are incorrect or old items in cart');
          return;
        }

        const fullProduct = getters.getProductVariantById(item.id);
        // verify item ID exists (this handles the use case of removing the Shopify flag and customers having old cart items in their session)
        if (!fullProduct) {
          // shopNotifier.sendError(new Error('Product not found'));
          return;
        }

          // remove monthly sub if holiday annual membership bundle is added
        const isHolidayAllInOne = fullProduct?.variants?.[0]?.uid.includes('fightcamp_holiday_all_in_one');
        if (isHolidayAllInOne) {
          console.log('removing membership from cart', getters.membershipInCart.id);
          await dispatch('removeItemFromCartV2', { id: getters?.membershipInCart?.variants?.[0]?.id });
        }

        // pass the membershipGID to the mutation to insert a membership if the product comes with one
        commit('insertItemToCartV2', { ...item, membershipGID: productVariant?.metadata?.membershipGID ?? null });

        await dispatch('updateCart');
      } catch (e) {
        // TODO set up handling errors for us to be notified etc
        console.error(e);
      }
    },
    async removeItemFromCartV2({ commit, dispatch, getters }, item) {
      try {
        commit('removeItemFromCartV2', item);

        // remove items that "belong" to a product (like warranties or deal items)
        const productToRemove = getters.getProductVariantById(item.id);
        if (productToRemove.productType === 'package') {
          const typesToRemove = ['warranty', 'subscription', 'package'];
          const productsToRemove = getters.cartProducts.shopifyItems.filter(p => typesToRemove.includes(p.productType)); // TODO how can we do this without hardcoding type?
          productsToRemove.forEach(p => {
            commit('removeItemFromCartV2', { id: p.variant.id });
          });
        }

        await dispatch('updateCart');
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    setPos(state, boolean) {
      state.isPosOn = boolean;
    },
    setCollections(state, { collection, transformedCollection }) {
      state.shopifyCollection = collection;
      state.transformedCollection = transformedCollection;
    },
    insertItemToCartV2(state, item) {
      const itemInCart = state.cart.find(i => i.id === item.id);
      if (itemInCart) {
        itemInCart.qty += 1;
      } else {
        state.cart.push({
          ...item,
          qty: 1,
        });
      }

      if (item.membershipGID) {
        state.cart.push({
          id: item.membershipGID,
          qty: 1,
          type: 'subs_only',
        });
      }
    },
    removeItemFromCartV2(state, itemToRemove) {
      const itemFoundInCart = state.cart.find(i => i.id === itemToRemove.id);
      if (!itemFoundInCart) return;

      itemFoundInCart.qty -= 1;
      if (itemFoundInCart.qty <= 0) {
        state.cart = state.cart.filter(i => i.id !== itemToRemove.id);
      }
    },
  },
  getters: {
    isPosOn: state => state.isPosOn,
    shopifyCollection: state => state.shopifyCollection,
    transformedCollection: state => state.transformedCollection,
    getProductVariantById: (_, getters) => (id) => {
      const products = getters.shopifyCollection;
      return products.find(p => p.variants.some(v => v.id === id));
    },
    membershipInCart: (state, getters) => {
      if (!getters.isShopifyOn) return null; // might not need this
      for (const cartItem of state.cart) { // should do getters.cart instead?
        const shopifyProduct = getters.getProductVariantById(cartItem.id);
        const subscriptionGraphQlIds = getters.subscriptionProducts.map((p) => p.id);

        if (subscriptionGraphQlIds.includes(shopifyProduct.id)) {
          return shopifyProduct;
        }
      }

      return null;
    },
    subscriptionProducts(state) { // should be getters.bizDevCollection?
      return state.shopifyCollection.filter(product => product.productType === 'subscription');
    },
    cartProducts: (_, getters) => {
      if (!getters.isShopifyOn) return null;
      const { cart, membershipInCart } = getters;

      let packageInCart = null;

      const shopifyItems = cart.map((cartItem) => {
        const fullProduct = getters.getProductVariantById(cartItem.id);
        const selectedVariant = fullProduct?.variants?.find(variant => variant.id === cartItem.id);

        const variant = {
          id: selectedVariant?.id,
          price: selectedVariant?.price,
          productName: selectedVariant?.name === 'Default Title' ? fullProduct?.title : selectedVariant?.name,
          qty: cartItem.qty,
          type: fullProduct?.productType || 'equip',
          items: [],
          pricingDescription: fullProduct?.description,
          pricingDetails: fullProduct?.description,
          product: {
            category: fullProduct?.productType,
            id: fullProduct?.id,
            img_url: fullProduct?.image,
            price: selectedVariant?.price,
            title: fullProduct?.title,
          },
          shippingRestricted: false,
          subscriptionNeeded: false,
          sku: selectedVariant.sku,
          bundleComponents: selectedVariant?.bundleComponents ?? null,
          uid: selectedVariant?.uid,
        };

        const fullProductWithSelectedVariant = { ...fullProduct, variant };
        if (fullProduct.productType === 'package') {
          packageInCart = {
            product: fullProductWithSelectedVariant,
            membership: membershipInCart,
            variantId: cartItem.id,
          };
        }

        return fullProductWithSelectedVariant;
      });

      return {
        shopifyItems,
        packageInCart,
      };
    },
    isShopifyPackage: (_, getters) => (id) => {
      const product = getters.getProductVariantById(id);
      return product?.productType === 'package' ?? false;
    },
    isHolidayPackageInCart: (_, getters) => {
      if (!getters.isShopifyOn || getters.isGQOrder) return false;
      for (const cartItem of getters.cart) {
        const shopifyProduct = getters.getProductVariantById(cartItem.id);
        if (shopifyProduct && shopifyProduct.variants?.[0]?.uid.includes('fightcamp_holiday_all_in_one')) {
          return true;
        }
      }

      return false;
    },
  },
};
